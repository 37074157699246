import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BorderBeam } from "@stianlarsen/border-beam";
import o1 from "../assets/o1.png";
import i1 from "../assets/i1.png";
import i2 from "../assets/i2.png";
import v1 from "../assets/v1.png";
import v2 from "../assets/v2.png";
import v3 from "../assets/v3.png";
import v4 from "../assets/v4.png";
import g4 from '../assets/g4.png';
import rightArrow from '../assets/right_arrow.png';
import FAQSection from "./Faq";
import Marquee from "react-fast-marquee";

const imgs = [
  {
    label: "Profiles",
    img: v1,
  },
  {
    label: "Employments",
    img: v2,
  },
  {
    label: "Payouts",
    img: v3,
  },
  {
    label: "Documents",
    img: v4,
  },
];

const cards = [
  {
    title: "Retail",
    description:
      "In the fast-paced retail world, quick and accessible background screening is essential. Our platform helps you hire smarter, reducing the risk of inventory loss and poor workplace culture.",
  },
  {
    title: "Technology",
    description:
      "In the tech industry, secure and swift background checks are crucial. Our advanced solutions help you hire the right talent, ensuring innovation and security at every step.",
  },
  {
    title: "Staffing",
    description:
      "Streamline your staffing process with our efficient background checks. Quickly place the best candidates, reduce hiring risks, and maintain a high-quality workforce.",
  },
  {
    title: "Banking",
    description:
      "Forget clunky verification processes. Team members can submit their details through a user-friendly mobile app, track results, and access instant in-app support.",
  },
  {
    title: "Manufacturing",
    description:
      "Ensure safety and reliability on your sites with our comprehensive background checks. From laborers to supervisors, we help you build a trustworthy team swiftly and efficiently.",
  },
  {
    title: "Healthcare",
    description:
      "When lives are at stake, trust our background checks to make informed hiring decisions, mitigate risks, and manage essential requirements for your healthcare organization.",
  },
];

const Product = () => {
  const [isHovered, setIsHovered] = useState(true);

  const handleMouseEnter = () => {
    setIsHovered(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(true);
  };


  useEffect(() => {
    AOS.init({
      duration: 1100,
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <>
      <section className="container products faq-sec" id="product">
        <div className="head-sec">
          <span>Product</span>
          <h4>
            One platform featuring customizable workflows for precise checks in
            real-time, every time.
          </h4>
          <p>
            Our cutting-edge deep tech empowers you to explore a wide array of
            coverage, guaranteeing you always have access to the essential data
            points you need
          </p>
        </div>
        <div className="img-wrap">
          <img alt="" src={o1} />
        </div>
      </section>
      <section className="background-new-container">
        <section className="container verify new-verify mt-6">
          <div data-aos="zoom-in-up">
            <div className="head-sec mt-4 ">
              <h4>Verify with the Most Comprehensive Data</h4>
              <p className="dim">
                Our platform provides a wide range of verification services,
                including KYC validation, credit score analysis, education and
                employment verification, AML checks, and more.
              </p>
              <button
                className="btn-light mt-2"
                onClick={() => {
                  window.open("https://sandbox.zky.co.in/request-demo", "_blank");
                }}
              >
                Get started for free
                <img src={rightArrow} alt="" className="right-arrow" />
              </button>
            </div>
           
          </div>
        </section>
        <div className="verify verify-less">
        <div className="cards">
              <Marquee pauseOnHover className="marquee-container">
                <ul>
                  {imgs?.map((ele) => (
                    <li key={ele} className="marquee-mar">
                      <span>{ele?.label}</span>
                      <img alt="" src={ele.img} />
                    </li>
                  ))}
                </ul>
              </Marquee>
            </div>
        </div>
        <section className="container products products2 instant">
          <img src={g4} className="img-g4" />
          <div className="flex-between" data-aos="fade-up-left"
            data-aos-duration="1200"
            data-aos-delay="700">
            <div className="head-sec" style={{padding: 0}}>
              <span>FAST TURNAROUND TIMES</span>
              <h4 className="new-text">Precise and reliable verification Instantly.</h4>
              <p className="new-text">
                Get ready for lightning-fast, spot-on verification! Our background
                checks deliver some of the quickest turnaround times in the
                industry, letting you breeze through your verification processes,
                tackle risks like a pro, and keep your operations flowing
                effortlessly!
              </p>
            </div>
            <img alt="" src={i1} />
          </div>
          <div
            className="flex-between"
            data-aos="fade-down-right"
            data-aos-duration="1200"
            data-aos-delay="700"
            style={{
              flexDirection: "row-reverse",
            }}
          >
            <div
              className="head-sec"
              style={{
                marginLeft: "5rem",
                padding:0
              }}
            >
              <span>OMNI CHANNEL</span>
              <h4 className="new-text">Give candidates an experience to be proud of</h4>
              <p className="new-text">
                Give candidates an experience they’ll rave about! Wave goodbye to
                clunky verification processes—team members can zip through
                submissions with our user-friendly mobile app, keep an eye on
                results, and get instant in-app support whenever they need it
              </p>
            </div>
            <img alt="" src={i2} />
          </div>
        </section>
        <section className="container verify industry-verify" id="industry">
          <div data-aos="zoom-in-up">
            <div className="head-sec new-hed-sec industries-head-sec">
              <span>INDUSTRIES</span>
              <h4>Trust and compliance for any industry.</h4>
              <p className="dim">
                Our platform is designed to meet the unique needs of various
                sectors, including product development, engineering, workforce
                management, the gig economy, hospitality, banking, and more.
              </p>
              <button
                className="btn-light mt-2"
                onClick={() => {
                  window.open("https://sandbox.zky.co.in/request-demo", "_blank");
                }}
              >
                Get started for free
                <img src={rightArrow} alt="" className="right-arrow" />
              </button>
            </div>
            <div className="cards industry">
              <ul>
                {cards.map((card, index) => (
                  <li className="industry-card" key={index}>
                    <div>
                      <h5>{card.title}</h5>
                      <p>{card.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </section>
      <section className="container products faq-sec">
        <FAQSection />
        <div className="get-in-touch" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {isHovered && <BorderBeam size={200} duration={6} colorFrom="#FFB200" colorTo="#FFB200" borderWidth={5} />}
          <div className="head-sec" style={{padding:"5rem"}}>
            <h4 className="mobile-head">Ready to elevate your onboarding experience?</h4>
            <p className="dim">
              Discover how Zky can transform your employee background
              verification process. Contact us today, and our enthusiastic
              industry experts will respond swiftly!
            </p>
            <button className="btn-light mt-2">Get in Touch</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;